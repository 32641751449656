
.order-detail-page {
  height: 100%;
  background: #f8f9fa;
  .page-info {
    background: #ffffff;
    border-radius: 8px;
    margin: 12px 12px 0 12px;
    padding: 15px;
    .info-hd {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .hd-tit {
        width: 249px;
        text-align: start;
      }
      .hd-status {
        font-size: 14px;
        color: #9fa6af;
        line-height: 24px;
      }
      .status-01 {
        color: #fc6c21;
      }
      .status-02 {
        color: #ff2223;
      }
    }
    .hd-number {
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      padding: 10px 0 13px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: none;
      .icon-copy {
        display: block;
        width: 12px;
        height: 12px;
        margin-left: 5px;
        background: url(../../assets/icons/icon-copy.png) center/100% 100%
          no-repeat;
      }
    }
  }
  .page-site {
    background: #ffffff;
    border-radius: 8px;
    margin: 12px 10px 0 10px;
    padding: 15px;
    .site-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .tit {
        font-size: 13px;
        color: #999999;
        line-height: 18px;
      }
      .tit-01 {
        color: #666666;
      }
      .txt {
        font-size: 13px;
        color: #333333;
        line-height: 18px;
        text-align: end;
      }
    }
  }
  .bt-protocol {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    bottom: 56px;
    left: 0;
    right: 0;
    font-size: 12px;
    color: #333333;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-top: 6px;
    background: #fff;
    .icon-selected {
      display: block;
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/icon-selected.png) center/100%,
        100% no-repeat;
      margin-right: 4px;
    }
    .icon-unselected {
      display: block;
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/icon-unselected.png) center/100%,
        100% no-repeat;
      margin-right: 4px;
    }
  }
  .page-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    .lt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .ft-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 10px;
      .icon-kefu {
        display: block;
        width: 20px;
        height: 20px;
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_kefu.png)
          center/100% 100% no-repeat;
      }
      .icon-wuliu {
        display: block;
        width: 20px;
        height: 20px;
        background: url(../../assets/icons/icon-wuliu.png) center/100% 100%
          no-repeat;
      }
      .txt {
        font-size: 11px;
        color: #333333;
        line-height: 16px;
      }
    }
  }
  .page-pay {
    background: #ffffff;
    border-radius: 8px;
    font-size: 13px;
    margin: 12px 10px 0 10px;
    .item {
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      .lt-tit {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }
      .rt {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-pay {
          display: block;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        .icon-wx {
          background: url(../../assets/image/pay/icon-wx.png) center/100%,
            100% no-repeat;
        }
        .icon-zfb {
          background: url(../../assets/image/pay/icon-zfb.png) center/100%,
            100% no-repeat;
        }
      }
      .ipt {
        text-align: end;
      }
    }
  }
  .pop-code {
    width: 350px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .title {
      padding: 15px 0 30px 0;
    }
    .btn {
      margin-top: 30px;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: right;
    margin-top: 12px;
    margin-right: 10px;
    .btn {
      padding: 0 10px;
      box-sizing: border-box;
      border-radius: 12px;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      margin-left: 8px;
    }
    .btn-01 {
      border: 1px solid #fc6c21;
      color: #fc6c21;
    }
  }
}
